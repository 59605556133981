import * as React from "react";
import {
  Button as PaperButton,
  Paragraph,
  Dialog,
  Portal,
} from "react-native-paper";
import THEME from "../../config/theme";
import { useCustomTheme } from '../../contexts/useCustomTheme';

export function AlertBox(props) {
    const { customFont, customColor } = useCustomTheme();

  return (
    <Portal>
      <Dialog
        visible={props.visible}
        onDismiss={() => props.onClose(false)}
        style={{
          width: Math.round(window.innerWidth * 0.7),
          alignSelf: 'center',
          backgroundColor: '#fffafa',
        }}
      >
        <Dialog.Title
          style={{
            fontFamily:
              customFont && customFont.fontFamilyTitle
                ? customFont.fontFamilyTitle
                : THEME.FONTFAMILY.BOLD,
            fontSize: THEME.FONTSIZE.BIG,
            color:
              customColor && customColor.text && customColor.text.app
                ? customColor.text.app
                : '#2e2e2e',
          }}
        >
          {props.title}
        </Dialog.Title>
        <Dialog.Content>
          <Paragraph
            style={{
              fontFamily:
                customFont && customFont.fontFamilyText
                  ? customFont.fontFamilyText
                  : THEME.FONTFAMILY.REGULAR,
              fontSize: THEME.FONTSIZE.MEDIUM,
              color:
                customColor && customColor.text && customColor.text.app
                  ? customColor.text.app
                  : '#2e2e2e',
              lineHeight: '1.5',
            }}
          >
            {props.message}
          </Paragraph>
        </Dialog.Content>
        <Dialog.Actions>
          <PaperButton
            labelStyle={{
              color:
                customColor && customColor.text && customColor.text.app
                  ? customColor.text.app
                  : '#2e2e2e',
              fontFamily:
                customFont && customFont.fontFamilyText
                  ? customFont.fontFamilyText
                  : THEME.FONTFAMILY.REGULAR,
              fontSize: THEME.FONTSIZE.BIG,
            }}
            onPress={() => {
              props.leftButtonFunction
                ? (props.onClose(false), props.leftButtonFunction())
                : props.onClose(false);
            }}
          >
            {props.leftButton ? props.leftButton : 'OK'}
          </PaperButton>
        </Dialog.Actions>
        {props.rightButton ? (
          <Dialog.Actions>
            <PaperButton
              labelStyle={{
                color:  customColor && customColor.text && customColor.text.app
                  ? customColor.text.app
                  : '#2e2e2e',
                fontFamily:
                  customFont && customFont.fontFamilyText
                    ? customFont.fontFamilyText
                    : THEME.FONTFAMILY.REGULAR,
                fontSize: THEME.FONTSIZE.BIG,
              }}
              onPress={() => {
                props.onClose(false), props.rightButtonFunction();
              }}
            >
              {props.rightButton}
            </PaperButton>
          </Dialog.Actions>
        ) : (
          <></>
        )}
      </Dialog>
    </Portal>
  );
}
