import React from "react";
import {
  FlatList,
  View,
  ScrollView,
  Modal,
  TouchableOpacity,
  Switch,
  Pressable
} from "react-native";
import { FooterText, SmallText } from "../../../config/theme/globalStyles";
import THEME from "../../../config/theme";
import { Ionicons } from "@expo/vector-icons";

import { ListContainer, SwitchView, FlatListView } from '../style'
import { useCustomTheme } from '../../../contexts/useCustomTheme';

const ItemRenderer = ({ index, title, selected, onUpdateValue }) => (
  <SwitchView>
    <Switch
      value={selected}
      onValueChange={(value) => onUpdateValue(index, value)}
    />
    <SmallText padding="0rem 0.5rem">{title}</SmallText>
  </SwitchView>
);

const FlatListMultipleSelection = (props) => {
  const { items, smallText, footerText } = props;

  const [open, setOpen] = React.useState(false);
  const openList = () => setOpen(true);

  const onUpdateValue = (index, value) => {
    items[index].selected = value;
    return props.setItems([...items]);
  };
  const renderItem = ({ item, index }) => (
    <ItemRenderer
      key={index}
      index={index}
      selected={item.selected}
      title={item?.title ? item.title : item.name}
      onUpdateValue={onUpdateValue}
    />
  );
  const { customColor } = useCustomTheme();
  return (
    <>
      <FlatListView>
        <TouchableOpacity onPress={openList}>
          <View
            style={{
              padding: '1rem',
              borderWidth: 1,
              borderColor:
                customColor && customColor.primary
                  ? customColor.primary
                  : THEME.COLORS.PRIMARY_800,
              borderRadius: '5px',
            }}
          >
            <FooterText textAlign="start" color={THEME.COLORS.TEXT_ABOUT}>
              {footerText}
            </FooterText>
          </View>
        </TouchableOpacity>
        {items.filter((item) => item.selected).length > 0 ? (
          <View style={{ height: '100%' }}>
            <SmallText textAlign="start">{smallText}</SmallText>
            {items
              .filter((item) => item.selected)
              .map((item) => (
                <FooterText
                  textAlign="start"
                  color={THEME.COLORS.TEXT_ABOUT}
                  key={item?.title ? item.title : item.name}
                >
                  {item?.title ? item.title : item.name}
                </FooterText>
              ))}
          </View>
        ) : (
          <></>
        )}
      </FlatListView>
      <Modal animationType="fade" transparent={true} visible={open}>
        <ScrollView
          style={{ backgroundColor: '#000000a0' }}
          contentContainerStyle={{
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ListContainer>
              <Pressable
                onPress={() => setOpen(false)}
                style={{ alignSelf: 'flex-end' }}
              >
                <Ionicons
                  name="close"
                  size={THEME.FONTSIZE.BIG}
                  color={
                    customColor && customColor.primary
                      ? customColor.primary
                      : THEME.COLORS.PRIMARY_900
                  }
                />
              </Pressable>
              <FlatList
                data={items.sort((a, b) =>
                  a?.title
                    ? a.title.localeCompare(b.title)
                    : a.name.localeCompare(b.name)
                )}
                renderItem={renderItem}
                keyExtractor={(item) =>
                  item?.title ? item?.title : item?.name
                }
              />
            </ListContainer>
          </View>
        </ScrollView>
      </Modal>
    </>
  );
};

export default FlatListMultipleSelection;
