import React, { useState, useEffect } from 'react';
import { View, Modal, StyleSheet, TouchableOpacity, Text } from 'react-native';
import TextInput from "../../../components/TextInput";
import {
  FooterText,
  SubTitle,
} from "../../../config/theme/globalStyles";
import Button from "../../../components/Button";
import {
  ViewTextInput,
  ViewButton,
} from "./style";
import { getProductById } from '../../../services/guru';
import { Dimensions } from "react-native";
import THEME from "../../../config/theme";

const screenWidth = Dimensions.get("window").width;

const CreateProductModal = ({ isVisible, onClose, onCreate }) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [productId, setProductId] = useState('');
  const [productInfo, setProductInfo] = useState('');
  const [offersInfos, setOffersInfos] = useState('');
  const [saveButtonVisible, setSaveButtonVisible] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [state, setState] = useState({
    visibleAlert: false,
    message: null,
  });

  const showAlert = (message) => {
    setState({ visibleAlert: true, message: message });
  };

  const hideAlert = () => {
    setState({ visibleAlert: false, message: null });
    onClose();
  };

  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000); // Esconde a mensagem após 3 segundos
      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage]);

  const handleCreate = async () => {
    try {
      setLoadingSave(true);

      // Chama a função onCreate passando planData
      const result = await onCreate(productInfo, offersInfos, productId);

      if (result.success) {
        showAlert("Novo produto adicionado com sucesso!");
        setShowSuccessMessage(true);
        onClose();
      } else if (result.error) {
        showAlert(result.error);
      }

    } catch (error) {
      console.error('Erro ao criar produto:', error);
      showAlert('Erro ao criar produto');
    } finally {
      setLoadingSave(false);
    }
  };

  const findPlanAndOffers = async () => {
    try {
      setLoadingSearch(true);

      const infos = await getProductById(productId);
      setProductInfo(infos.product);
      setOffersInfos(infos.offers);
      setSaveButtonVisible(true);
    } catch (error) {
      console.error('Erro ao buscar informações do produto:', error);
      // Adicione lógica de tratamento de erro conforme necessário
    } finally {
      setLoadingSearch(false);
    }
  };

  return (
    <Modal
      animationType="fade"
      isVisible={isVisible}
      onBackdropPress={onClose}
      transparent={true}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <TouchableOpacity onPress={onClose} style={styles.closeButton}>
            <Text style={styles.closeButtonText}>x</Text>
          </TouchableOpacity>
          {!saveButtonVisible &&
            <SubTitle padding="0rem 0rem 0.5rem 0rem" color="#2e2e2e">
              Adicionar Produto no seu aplicativo:
            </SubTitle>
          }
          <ViewTextInput>
            <TextInput
              placeholder="Digite o ID do Produto"
              color="black"
              backgroundColor="white"
              value={productId}
              onChangeText={(id) => setProductId(id)}
            />
            <FooterText padding="0.2rem 0rem" color="#2e2e2e">
              {!saveButtonVisible ? "O ID do produto deve ser o código gerado na Guru Digital" : "Produto encontrado com sucesso!"}
            </FooterText>
            {state.visibleAlert && (
              <FooterText color={THEME.COLORS.ALERT}>{state.message}</FooterText>
            )}
          </ViewTextInput>
          <ViewButton>
            {!saveButtonVisible &&
              <Button
                title="Buscar informações do produto"
                isLoading={loadingSearch}
                onPress={findPlanAndOffers}
                fontSize={THEME.FONTSIZE.EXTRASMALL}
              />
            }
          </ViewButton>
          {saveButtonVisible && (
            <ViewButton style={{ marginTop: '1rem' }}>
              <Button
                title="Salvar"
                isLoading={loadingSave}
                onPress={handleCreate}
                colorbutton={"#3FC380E6"}
              />
              <Button
                title="Cancelar"
                onPress={onClose}
                colorbutton={"#FA5D5D"}
                margin="0rem 0rem 0rem 1rem"
              />
            </ViewButton>
          )}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modalView: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
    width: screenWidth > 980 ? screenWidth * 0.6 : screenWidth * 0.9,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButtonText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});

export default CreateProductModal;